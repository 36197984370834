import { render, staticRenderFns } from "./StockPrefixTemplate.vue?vue&type=template&id=a221d942&scoped=true&"
import script from "./StockPrefixTemplate.vue?vue&type=script&lang=js&"
export * from "./StockPrefixTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a221d942",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a221d942')) {
      api.createRecord('a221d942', component.options)
    } else {
      api.reload('a221d942', component.options)
    }
    module.hot.accept("./StockPrefixTemplate.vue?vue&type=template&id=a221d942&scoped=true&", function () {
      api.rerender('a221d942', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Papers/pages/Paper/components/Templates/StockPrefixTemplate.vue"
export default component.exports